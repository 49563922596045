// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.bg_home_img {
    background-image: url(/assets/images/bg/bg-home.jpg) !important;
}

.about_subtitle {
    margin-top: 20px;
}

.color-black .mainmenunav ul.mainmenu>li>a {
    color: #727272;
}